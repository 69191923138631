/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "./chunks/tslib.es6.js";
import { geometryTypes as e } from "./geometry.js";
import r from "./PopupTemplate.js";
import { symbolTypes as s } from "./symbols.js";
import { ClonableMixin as i } from "./core/Clonable.js";
import { deprecatedConstructorSignature as o } from "./core/deprecate.js";
import { isSerializable as a, JSONSupport as l } from "./core/JSONSupport.js";
import n from "./core/Logger.js";
import { when as p } from "./core/reactiveUtils.js";
import { generateUID as h } from "./core/uid.js";
import { property as u } from "./core/accessorSupport/decorators/property.js";
import "./core/has.js";
import "./core/RandomLCG.js";
import { subclass as m } from "./core/accessorSupport/decorators/subclass.js";
import { fromJSON as g } from "./geometry/support/jsonUtils.js";
var y;
function c(t) {
  if (!t) return null;
  const e = {};
  for (const r in t) {
    const s = g(t[r]);
    s && (e[r] = s);
  }
  return 0 !== Object.keys(e).length ? e : null;
}
function f(t) {
  if (null == t) return null;
  const e = {};
  for (const r in t) {
    const s = t[r];
    s && (e[r] = s.toJSON());
  }
  return 0 !== Object.keys(e).length ? e : null;
}
let b = y = class extends i(l) {
  constructor(t) {
    super(t), this.isAggregate = !1, this.layer = null, this.origin = null, this.popupTemplate = null, this.sourceLayer = null, this._version = 0, Object.defineProperty(this, "uid", {
      value: h(),
      configurable: !0
    }), Object.defineProperty(this, "_lastMeshTransform", {
      value: {},
      configurable: !0,
      writable: !0,
      enumerable: !1
    }), arguments.length > 1 && o(n.getLogger(this), "Graphic", {
      version: "4.30"
    });
  }
  initialize() {
    this._watchMeshGeometryChanges();
  }
  set aggregateGeometries(t) {
    const e = this._get("aggregateGeometries");
    JSON.stringify(e) !== JSON.stringify(t) && this._set("aggregateGeometries", t);
  }
  set attributes(t) {
    const e = this._get("attributes");
    e !== t && (this._set("attributes", t), this._notifyLayer("attributes", e, t));
  }
  set geometry(t) {
    const e = this._get("geometry");
    e !== t && (this._set("geometry", t), "mesh" !== t?.type && this._notifyLayer("geometry", e, t));
  }
  set symbol(t) {
    const e = this._get("symbol");
    e !== t && (this._set("symbol", t), this._notifyLayer("symbol", e, t));
  }
  get version() {
    return this._version;
  }
  set visible(t) {
    const e = this._get("visible");
    e !== t && (this._set("visible", t), this._notifyLayer("visible", e, t));
  }
  cloneShallow() {
    return new y({
      aggregateGeometries: this.aggregateGeometries,
      attributes: this.attributes,
      geometry: this.geometry,
      isAggregate: this.isAggregate,
      layer: this.layer,
      popupTemplate: this.popupTemplate,
      sourceLayer: this.sourceLayer,
      symbol: this.symbol,
      visible: this.visible,
      origin: this.origin
    });
  }
  getEffectivePopupTemplate(t = !1) {
    if (this.popupTemplate) return this.popupTemplate;
    const e = this.origin && "layer" in this.origin ? this.origin.layer : null;
    for (const r of [e, this.sourceLayer, this.layer]) if (r) {
      if ("popupTemplate" in r && r.popupTemplate) return r.popupTemplate;
      if (t && "defaultPopupTemplate" in r && null != r.defaultPopupTemplate) return r.defaultPopupTemplate;
    }
    return null;
  }
  getAttribute(t) {
    return this.attributes?.[t];
  }
  setAttribute(t, e) {
    if (this.attributes) {
      const r = this.getAttribute(t);
      this.attributes[t] = e, this._notifyLayer("attributes", r, e, t);
    } else this.attributes = {
      [t]: e
    }, this._notifyLayer("attributes", void 0, e, t);
  }
  getObjectId() {
    return this.sourceLayer && "objectIdField" in this.sourceLayer && this.sourceLayer.objectIdField ? this.getAttribute(this.sourceLayer.objectIdField) : null;
  }
  toJSON() {
    return {
      aggregateGeometries: f(this.aggregateGeometries),
      geometry: null != this.geometry ? this.geometry.toJSON() : null,
      symbol: null != this.symbol ? this.symbol.toJSON() : null,
      attributes: a(this.attributes) ? this.attributes.toJSON() : {
        ...this.attributes
      },
      popupTemplate: this.popupTemplate?.toJSON() ?? null
    };
  }
  notifyMeshTransformChanged(t = {}) {
    const {
      geometry: e
    } = this;
    if ("mesh" === e?.type) {
      const r = {
        origin: e.origin,
        transform: e.transform
      };
      this._notifyLayer("origin-transform", r, r, t.action);
    }
  }
  _notifyLayer(t, e, r, s) {
    if (this._version++, !this.layer || !("graphicChanged" in this.layer)) return;
    const i = {
      graphic: this,
      property: t,
      oldValue: e,
      newValue: r
    };
    "origin-transform" === t && (i.action = s), "attributes" === t && (i.attributeName = s), this.layer.graphicChanged(i);
  }
  _watchMeshGeometryChanges() {
    this.addHandles([p(() => "mesh" === this.geometry?.type && this.geometry.vertexSpace.origin ? {
      localMatrix: this.geometry.transform?.localMatrix,
      origin: this.geometry.vertexSpace.origin
    } : void 0, ({
      localMatrix: t,
      origin: e
    }) => {
      this._lastMeshTransform.localMatrix === t && this._lastMeshTransform.origin === e || (this._lastMeshTransform.localMatrix = t, this._lastMeshTransform.origin = e, this.notifyMeshTransformChanged());
    }), p(() => "mesh" === this.geometry?.type ? {
      vertexAttributes: this.geometry.vertexAttributes
    } : void 0, () => {
      const t = this.geometry;
      "mesh" === t?.type && t.vertexSpace.origin ? (this._lastMeshTransform.localMatrix = t.transform?.localMatrix, this._lastMeshTransform.origin = t.vertexSpace.origin) : (this._lastMeshTransform.localMatrix = void 0, this._lastMeshTransform.origin = void 0), this._notifyLayer("geometry", this.geometry, this.geometry);
    }, {
      equals: (t, e) => t === e,
      sync: !0
    })]);
  }
};
t([u({
  value: null,
  json: {
    read: c
  }
})], b.prototype, "aggregateGeometries", null), t([u({
  value: null
})], b.prototype, "attributes", null), t([u({
  value: null,
  types: e,
  json: {
    read: g
  }
})], b.prototype, "geometry", null), t([u({
  type: Boolean
})], b.prototype, "isAggregate", void 0), t([u({
  clonable: "reference"
})], b.prototype, "layer", void 0), t([u({
  clonable: "reference"
})], b.prototype, "origin", void 0), t([u({
  type: r
})], b.prototype, "popupTemplate", void 0), t([u({
  clonable: "reference"
})], b.prototype, "sourceLayer", void 0), t([u({
  value: null,
  types: s
})], b.prototype, "symbol", null), t([u({
  clonable: !1,
  json: {
    read: !1,
    write: !1
  }
})], b.prototype, "_version", void 0), t([u({
  type: Boolean,
  value: !0
})], b.prototype, "visible", null), b = y = t([m("esri.Graphic")], b);
const d = b;
export { d as default };